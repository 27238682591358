<template>
  <div>
    <header>
      <div v-if="navBackdrop" @click.prevent="hideNavigation" class="nav-backdrop"></div>
      <div class="dektop-navigation">
        <nav>
          <b-container fluid>
            <b-container>
              <b-row>
                <b-col cols="2">
                  <router-link to="/" exact><img class="dektop-navigation__logo img-fluid" v-lazy="this.header_logo" alt="logo"></router-link>
                </b-col>
                <b-col class="text-right" cols="10">
                  <a :href="this.nav_link_url_1" class="btn main-button main-button--transparent dektop-navigation__link">{{ $prismic.richTextAsPlain(this.nav_link_label_1) }}</a>
                  <b-dropdown v-if="this.nav_sub_navigation.length > 0" :text="$prismic.richTextAsPlain(this.nav_link_label_2)" class="main-button main-button--dropdown main-button--transparent" no-caret>
                    <span v-for="(item, index) in this.nav_sub_navigation" :key="`${index}`" @click="hideNavigation"><b-dropdown-item :href="item.link_url.url">{{ $prismic.richTextAsPlain(item.link_label) }}</b-dropdown-item></span>
                  </b-dropdown>
                  <a :href="this.nav_link_url_3" class="btn main-button main-button--transparent dektop-navigation__link">{{ $prismic.richTextAsPlain(this.nav_link_label_3) }}</a>
                  <a :href="this.nav_link_url_4" class="btn main-button main-button--wide dektop-navigation__link">{{ $prismic.richTextAsPlain(this.nav_link_label_4) }}</a>
                </b-col>
              </b-row>
            </b-container>
          </b-container>
        </nav>
      </div>
      <div class="mobile-navbar">
        <b-col cols="12">
          <span @click="hideNavigation"><router-link to="/" exact><img class="dektop-navigation__logo img-fluid" v-lazy="this.header_logo" alt="logo"></router-link></span>
          <a :href="this.nav_link_url_4" class="btn main-button main-button--wide dektop-navigation__link d-none d-md-inline-block tablet-button">{{ $prismic.richTextAsPlain(this.nav_link_label_4) }}</a>
          <div @click.prevent='showNavigation'>
            <div class="nav-icon" :class="showMobileMenu ? 'open' : ''">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </b-col>
      </div>
      <div class="mobile-naviagtion" :class="showMobileMenu ? 'mobile-menu-active' : ''">
        <div class="mobile-naviagtion__content">
          <span @click="hideNavigation"><a :href="this.nav_link_url_1" class="mobile-naviagtion__link">{{ $prismic.richTextAsPlain(this.nav_link_label_1) }}</a></span>
          <b-dropdown v-if="this.nav_sub_navigation.length > 0" :text="$prismic.richTextAsPlain(this.nav_link_label_2)" class="mobile-naviagtion__link mobile-naviagtion__link--dropdown main-button--dropdown main-button--transparent p-0 w-100" no-caret>
            <span v-for="(item, index) in this.nav_sub_navigation" :key="`${index}`" @click="hideNavigation"><b-dropdown-item :href="item.link_url.url">{{ $prismic.richTextAsPlain(item.link_label) }}</b-dropdown-item></span>
          </b-dropdown>
          <span @click="hideNavigation"><a :href="this.nav_link_url_3" class="mobile-naviagtion__link">{{ $prismic.richTextAsPlain(this.nav_link_label_3) }}</a></span>
          <span @click="hideNavigation"><a :href="this.nav_link_url_4" class="mobile-naviagtion__link">{{ $prismic.richTextAsPlain(this.nav_link_label_4) }}</a></span>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data: () => {
    return {
      showMobileMenu: false,
      navBackdrop: false,
      header_logo: null,
      nav_link_label_1: null,
      nav_link_url_1: null,
      nav_link_label_2: null,
      nav_link_label_3: null,
      nav_link_url_3: null,
      nav_link_label_4: null,
      nav_link_url_4: null,
      nav_sub_navigation: []
    }
  },
  methods: {
    async getHeaderContent () {
      await this.$prismic.client.getSingle('header')
        .then((document) => {
          // header section
          this.header_logo = document.data.header_logo.url
          this.nav_link_label_1 = document.data.nav_link_label_1
          this.nav_link_url_1 = document.data.nav_link_url_1.url
          this.nav_link_label_2 = document.data.nav_link_label_2
          this.nav_link_label_3 = document.data.nav_link_label_3
          this.nav_link_url_3 = document.data.nav_link_url_3.url
          this.nav_link_label_4 = document.data.nav_link_label_4
          this.nav_link_url_4 = document.data.nav_link_url_4.url
          this.nav_sub_navigation = document.data.nav_sub_navigation
        })
    },
    showNavigation () {
      this.showMobileMenu = !this.showMobileMenu
      this.navBackdrop = !this.navBackdrop
    },
    hideNavigation () {
      this.showMobileMenu = false
      this.navBackdrop = false
    }
  },
  created () {
    this.getHeaderContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./HeaderComponent.scss";
</style>
